import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import { Link, useParams } from "react-router-dom";

const Investment = () => {
  const { id } = useParams();
  const [investment, setInvestment] = useState();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const getPayout = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/investment/${id}`,
          {
            withCredentials: true,
          }
        );

        setInvestment(res.data);

        // setUser(userRes.data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        toast.error(message);
        setLoading(false);
      }
    };
    getPayout();
  }, [id]);

  if (loading) {
    <Loader />;
  }

  const activate = async () => {
    setLoad(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/activate/${id}`,
        {
          withCredentials: true,
        }
      );

      setInvestment(res.data.investment);
      setLoad(false);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(message);
      setLoad(false);
    }
  };
  return (
    <div className="h-screen">
      <div className="my-9 lg:my-11">
        <h1 className=" font-bold text-green-600 text-2xl lg:text-4xl  ">
          Investment
        </h1>
      </div>

      <div className=" bg-white md:w-[45%] shadow-lg mx-auto p-3 lg:p-5">
        <div className=" font-bold text-green-700 lg:text-2xl mb-3">
          {investment?.type}
        </div>

        <div className=" mt-3">
          <p className="  text-green-700 text-xs lg:text-sm font-medium capitalize">
            User
          </p>
          <p className=" capitalize text-gray-700 text-sm lg:text-lg font-medium">
            {investment?.name}
          </p>
          <p className=" text-gray-500 text-sm font-medium">
            {investment?.email}
          </p>
        </div>

        <div className=" mt-3">
          <p className=" text-green-700 text-xs lg:text-sm font-medium capitalize">
            referral code
          </p>
          <p className=" text-gray-700 text-sm lg:text-lg font-medium">
            {investment?._id}
          </p>
        </div>

        <div className=" mt-3">
          <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
            Amount
          </p>
          <p className=" text-gray-700  text-sm lg:text-lg font-medium">
            &#8358; {new Intl.NumberFormat().format(investment?.amount)}
          </p>
        </div>

        <div className=" mt-3">
          <p className="text-xs text-green-700 lg:text-sm font-medium capitalize">
            Date
          </p>
          <p className=" text-gray-700  text-sm lg:text-lg font-medium">
            {moment(investment?.createdAt).format("MMM Do YYYY")}
          </p>
        </div>

        <div className=" mt-3">
          <p className="text-xs text-green-700 lg:text-sm font-medium capitalize">
            Payout
          </p>
          <p className=" text-gray-700 text-sm lg:text-lg font-medium">
            &#8358; {new Intl.NumberFormat().format(investment?.payout)}
          </p>
        </div>

        <div className=" mt-3">
          <p className="text-xs text-green-700 lg:text-sm font-medium capitalize">
            Maturity
          </p>
          <p className=" text-gray-700 text-sm lg:text-lg font-medium">
            {moment(investment?.maturity).format("MMM Do YYYY")}
          </p>
        </div>

        {!investment?.activated && (
          <div className=" mt-5 bg-yellow-200 w-full p-3 text-center flex justify-center items-center rounded">
            <p className="text-xs text-yellow-700 lg:text-sm font-medium capitalize">
              Pending Validation
            </p>
          </div>
        )}

        <div className=" flex gap-3 my-5">
          {!investment?.activated && (
            <button
              onClick={activate}
              className=" font-medium bg-green-700 text-white py-2.5  rounded w-full disabled:bg-green-300"
            >
              {load ? "Activating" : "Activate"}
            </button>
          )}

          <Link
            className=" cursor-pointer text-center font-medium bg-green-700 text-white py-2.5  rounded w-full disabled:bg-green-300"
            to={`/admin/payout/${id}`}
          >
            Payout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Investment;
