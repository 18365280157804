import React, { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";
// import { ImStatsBars } from "react-icons/im";
import { TbPigMoney } from "react-icons/tb";

// import AdminBarChart from "../components/AdminBarChart";
// import { BsArrowBarDown, BsArrowBarUp } from "react-icons/bs";
import { toast } from "react-toastify";
import axios from "axios";
// import moment from "moment";
import Loader from "../components/Loader";
import AdminHeader from "../components/AdminHeader";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState({});
  const [totalIntrest, setTotalIntrest] = useState({});
  const [pendingWithdraw, setPendingWithdraw] = useState([]);

  const [loading, setLoading] = useState(false);

  const getAdminDashboard = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/all-users`,
        {
          withCredentials: true,
        }
      );

      const transRes = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/investments`,
        {
          withCredentials: true,
        }
      );

      const intrestRes = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/total-intrest`,
        {
          withCredentials: true,
        }
      );

      const investmentRes = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/total-investment`,
        {
          withCredentials: true,
        }
      );

      const withdrawRes = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/pending-withdraws`,
        {
          withCredentials: true,
        }
      );

      setTransactions(transRes.data.investments);
      setUsers(res.data.users);
      setTotalInvestment(investmentRes.data.totalInvestments);
      setTotalIntrest(intrestRes.data.totalIntrest);
      setPendingWithdraw(withdrawRes.data.withdraws);
      setLoading(false);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdminDashboard();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <AdminHeader />
      <h1 className=" font-bold text-green-600 text-2xl lg:text-4xl  my-9 lg:my-11">
        Admin Dashboard
      </h1>
      {/* cards */}
      <div className=" grid grid-cols-4 gap-5">
        <div className=" bg-white px-5 py-8 shadow-lg rounded flex items-center gap-3">
          <div className=" p-4 bg-green-100 text-green-500 flex justify-center items-center rounded-md">
            <FaUsers size={30} />
          </div>

          <div className="">
            <h2 className=" text-2xl font-semibold"> {users.length}</h2>
            <p className=" capitalize  font-medium text-gray-900">Users</p>
          </div>
        </div>

        <div className=" bg-white px-5 py-8 shadow-lg rounded flex items-center gap-3">
          <div className=" p-4 bg-red-100 text-red-500 flex justify-center items-center rounded-md">
            <GiMoneyStack size={30} />
          </div>

          <div className="">
            <h2 className=" text-2xl font-semibold">
              {" "}
              &#8358; {new Intl.NumberFormat().format(totalInvestment)}
            </h2>
            <p className=" capitalize  font-medium text-gray-900">
              Total Revenue
            </p>
          </div>
        </div>

        <div className=" bg-white px-5 py-8 shadow-lg rounded flex items-center gap-3">
          <div className=" p-4 bg-purple-100 text-purple-500 flex justify-center items-center rounded-md">
            <TbPigMoney size={30} />
          </div>

          <div className="">
            <h2 className=" text-2xl font-semibold">
              {" "}
              &#8358; {new Intl.NumberFormat().format(totalIntrest)}
            </h2>
            <p className=" capitalize  font-medium text-gray-900">
              Total Investment
            </p>
          </div>
        </div>

        <div className=" bg-white px-5 py-8 shadow-lg rounded flex items-center gap-3">
          <div className=" p-4 bg-purple-100 text-purple-500 flex justify-center items-center rounded-md">
            <TbPigMoney size={30} />
          </div>

          <div className="">
            <h2 className=" text-2xl font-semibold">
              {new Intl.NumberFormat().format(pendingWithdraw?.length)}
            </h2>
            <p className=" capitalize  font-medium text-gray-900">
              Pending Withdrawls
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-between my-16">
        <div className=" w-[55%] bg-white p-5 shadow-md sm:rounded-sm">
          <div className=" mb-6 ">
            <p className=" font-medium  text-xl ">Investments</p>
            <p className=" text-xs text-gray-500 ">
              {transactions?.length} Investments
            </p>
          </div>
          <div className="relative overflow-x-auto ">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400">
              <thead className=" text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-4">
                    <div class="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 0"
                      />
                      <label for="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-3">
                    type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    status
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactions?.slice(0, 7).map((transaction) => {
                  return (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="w-4 p-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-table-search-1"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="checkbox-table-search-1"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <th
                        scope="row"
                        className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <div className="pl-3">
                          <div className="text-base font-semibold">
                            {transaction?.name}
                          </div>
                          <div className="text-xs font-normal text-gray-500">
                            {transaction?.email}
                          </div>
                        </div>
                      </th>

                      <td
                        className={`text-gray-900 px-6 py-4 font-medium capitalize`}
                      >
                        {transaction?.type}
                      </td>
                      <td
                        className={`text-gray-900  px-6 py-4 font-semibold capitalize`}
                      >
                        &#8358;{" "}
                        {new Intl.NumberFormat().format(transaction?.amount)}
                      </td>
                      <td
                        className={`text-gray-900 px-6 py-4 font-medium capitalize`}
                      >
                        {!transaction?.activated ? (
                          <span className="bg-gray-200 p-2 rounded text-gray-500">
                            Inactive{" "}
                          </span>
                        ) : (
                          <span className="bg-green-200 p-2 rounded text-green-500">
                            active
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className=" w-[43%] bg-white p-5 shadow-md sm:rounded-sm">
          <div className=" mb-6 ">
            <p className=" font-medium  text-xl ">Users</p>
          </div>
          <div className="relative overflow-x-auto ">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400">
              <thead className=" text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-4">
                    S/N
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Investment
                  </th>
                  <th scope="col" className="px-6 py-3">
                    KYC Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.slice(0, 7).map((user, index) => {
                  return (
                    <tr
                      key={user._id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="w-4 p-4">{index + 1}</td>

                      <th
                        scope="row"
                        className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <div className="pl-3">
                          <div className="text-base font-semibold">
                            {user?.firstname} {user?.lastname}
                          </div>
                          <div className="text-xs font-normal text-gray-500">
                            {user?.email}
                          </div>
                        </div>
                      </th>

                      <td className="px-6 py-4 font-semibold text-green-700">
                        &#8358;{" "}
                        {new Intl.NumberFormat().format(user?.accountBalance)}
                      </td>

                      {!user?.kycStatus && (
                        <td className="px-6 py-4">
                          <div className="flex items-center capitalize font-medium  text-gray-500">
                            <div className="h-2.5 w-2.5   rounded-full bg-gray-500 mr-2"></div>{" "}
                            {/* {user.kycStatus} */}Nil
                          </div>
                        </td>
                      )}

                      {user?.kycStatus === "pending" && (
                        <td className="px-6 py-4">
                          <div className="flex items-center capitalize font-medium  text-yellow-500">
                            <div className="h-2.5 w-2.5   rounded-full bg-yellow-500 mr-2"></div>{" "}
                            {/* {user.kycStatus} */}
                            pending
                          </div>
                        </td>
                      )}

                      {user?.kycStatus === "disapprove" && (
                        <td className="px-6 py-4">
                          <div className="flex items-center capitalize font-medium  text-red-500">
                            <div className="h-2.5 w-2.5   rounded-full bg-red-500 mr-2"></div>{" "}
                            {/* {user.kycStatus} */}
                            Rejected
                          </div>
                        </td>
                      )}

                      {user?.kycStatus === "approved" && (
                        <td className="px-6 py-4">
                          <div className="flex items-center capitalize font-medium  text-green-500">
                            <div className="h-2.5 w-2.5   rounded-full bg-green-500 mr-2"></div>{" "}
                            {/* {user.kycStatus} */}
                            Accepted
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
