import React from "react";
import AdminHeader from "../components/AdminHeader";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import moment from "moment";

// import { FaRegCopy } from "react-icons/fa";

const Payout = () => {
  const { id } = useParams();
  const [investment, setInvestment] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [close, setClose] = useState(false);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    const getPayout = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/investment/${id}`,
          {
            withCredentials: true,
          }
        );

        const userRes = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/user/${res.data.userId}`,
          {
            withCredentials: true,
          }
        );

        setInvestment(res.data);
        setUser(userRes);
        setLoading(false);

        // setUser(userRes.data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        toast.error(message);
        setLoading(false);
      }
    };
    getPayout();
  }, [id, investment?.userId]);

  if (loading) {
    <Loader />;
  }

  const payout = async () => {
    setLoad(true);

    if (!amount) {
      setLoad(false);
      return toast.error("All fields are required");
    }

    const userData = { amount };

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/payout/${investment?._id}`,
        userData,
        {
          withCredentials: true,
        }
      );

      const investmentRes = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/investment/${id}`,
        {
          withCredentials: true,
        }
      );

      setInvestment(investmentRes.data);
      setLoad(false);
      toast.success("Payout Successfull");
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      setLoad(false);

      toast.error(message);
    }
  };

  const highpayout = async () => {
    setLoad(true);
    if (!amount) {
      setLoad(false);
      return toast.error("All fields are required");
    }

    const userData = { amount };
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/high-payout/${investment?._id}`,
        userData,
        {
          withCredentials: true,
        }
      );

      const investmentRes = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/investment/${id}`,
        {
          withCredentials: true,
        }
      );

      setInvestment(investmentRes.data);
      setLoad(false);
      toast.success("Payout Successfull");
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log(error);

      toast.error(message);
    }
  };

  const closeInvestment = async () => {
    setClose(true);
    try {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/close/${investment?._id}`,

        {
          withCredentials: true,
        }
      );

      toast.success("Investment closed Successfull");
      setClose(false);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      setClose(false);

      toast.error(message);
    }
  };

  return (
    <div>
      <AdminHeader />
      <h1 className=" font-bold text-green-600 text-2xl lg:text-4xl  my-9 lg:my-11">
        Payout
      </h1>
      <div className="lg:w-[45%] mx-auto shadow-lg rounded p-5 bg-white my-16">
        <span className=" font-semibold text-2xl  text-green-700 uppercase">
          {investment?.type}
        </span>

        <div className=" mt-3">
          <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
            Amount
          </p>
          <p className="  text-sm lg:text-lg font-medium">
            &#8358; {new Intl.NumberFormat().format(investment?.amount)}
          </p>
        </div>

        <div className=" mt-3">
          <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
            Date
          </p>
          <p className="   text-sm lg:text-lg font-medium">
            {moment(investment?.createdAt).format("MMM Do YYYY")}
          </p>
        </div>

        <div className=" mt-3">
          <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
            Maturity
          </p>
          <p className="   text-sm lg:text-lg font-medium">
            {moment(investment?.maturity).format("MMM Do YYYY")}
          </p>
        </div>

        <div className=" mt-3">
          <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
            Status
          </p>
          {investment?.status === "withdraw" ? (
            <p className=" text-center w-fit mt-1  p-2 text-sm bg-red-100 rounded text-red-600">
              {investment?.status}
            </p>
          ) : investment?.status === "" ? (
            <p className=" text-center w-fit mt-1  p-2 text-sm bg-gray-100 rounded text-gray-600">
              Pending
            </p>
          ) : (
            <p className=" text-center w-fit mt-1  p-2 text-sm bg-green-100 rounded text-green-600">
              {investment?.status}
            </p>
          )}
        </div>

        {investment?.type === "High Risk Investment" ? (
          <div className=" mt-3">
            <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
              payout
            </p>
            <p className="  text-sm lg:text-lg font-medium">
              &#8358; {new Intl.NumberFormat().format(investment?.payout)}
            </p>
          </div>
        ) : (
          <div className=" mt-3">
            <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
              payout
            </p>
            <p className="  text-sm lg:text-lg font-medium">
              &#8358;{" "}
              {new Intl.NumberFormat().format(
                investment?.payout + investment?.amount
              )}
            </p>
          </div>
        )}

        <div className=" mt-3">
          <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
            Bank
          </p>
          <p className="  text-sm lg:text-lg font-medium">
            {!user?.bank ? "Not added" : user?.bank}
          </p>
        </div>

        <div className=" mt-3">
          <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
            Account Number
          </p>
          <p className="  text-sm lg:text-lg font-medium">
            {!user?.accountNumber ? "Not added" : user?.accountNumber}
          </p>
        </div>

        <div className=" mt-3">
          <p className=" text-xs lg:text-sm text-green-700  font-medium capitalize">
            Paid
          </p>
          <p className="  text-sm lg:text-lg font-medium">
            &#8358; {new Intl.NumberFormat().format(investment?.paid)}
          </p>
        </div>

        <div className=" mt-14 ">
          <div className="relative  mb-2">
            <input
              type="text"
              name="amount"
              id="amount"
              className="block px-2.5 py-3 lg:p-4 w-full  text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />

            <label
              htmlFor="amount"
              className="absolute   text-gray-500 scale-75 -top-3 lg:text-lg   bg-white px-2  left-1"
            >
              Amount
            </label>
          </div>
          {investment?.type === "Diamond Investment" ? (
            // <AdminPay handlePay={highpayout} amount={investment?.intrest} />
            <button
              onClick={highpayout}
              className="text-white text-sm lg:text-lg bg-[#08432d]  rounded py-2.5 lg:p-4 w-full capitalize font-medium my-4  disabled:bg-green-300"
              disabled={load}
            >
              {load ? "loading" : " Payout"}
            </button>
          ) : (
            // <AdminPay
            //   handlePay={payout}
            //   amount={investment?.payout + investment?.intrest}
            // />
            <button
              onClick={payout}
              className="text-white text-sm lg:text-lg bg-[#08432d]  rounded py-2.5 lg:p-4 w-full capitalize font-medium my-4  disabled:bg-green-300"
              disabled={load}
            >
              {load ? "loading" : " Payout"}
            </button>
          )}

          <button
            onClick={closeInvestment}
            className="text-white text-sm lg:text-lg bg-red-600 rounded py-2.5 lg:p-4 w-full uppercase font-medium my-4"
          >
            {close ? "loading" : "Close Investment"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payout;
